import { useSession } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SignInStage, TSignInStage } from './SignInPanelContext';

export const useSignInPanelProvider = () => {
  const { user } = useSession();
  const [prefilledEmail, setPrefilledEmail] = useState<string | undefined>();
  const [currentStage, setCurrentStage] = useState<TSignInStage>(
    SignInStage.SIGN_IN,
  );

  useEffect(() => {
    setPrefilledEmail(user?.email);
  }, [user?.email]);

  const goToStage = useCallback(
    (screen: TSignInStage) => {
      setCurrentStage(screen);
    },
    [setCurrentStage],
  );

  const context = useMemo(
    () => ({ currentStage, goToStage, prefilledEmail }),
    [currentStage, goToStage, prefilledEmail],
  );

  return context;
};
