import { gql } from '@apollo/client';

export const GET_PATIENT_EXAMS = gql`
  query PatientExams($examsFilter: FilterFindManyExamInput) {
    exams(filter: $examsFilter) {
      _id
      examDate
      physician
      findings {
        relevant
      }
      patient {
        _id
        identificationType
        identificationNumber
      }
      technology
      priority
      user {
        firstName
        lastName
        firebaseId
        institution {
          _id
        }
      }
      medicalNotes {
        _id
        user {
          _id
          firstName
          lastName
        }
        content
        creationDate
      }
      status
    }
  }
`;
