import { ISignInTabPanel } from '../auth.types';
import { SignInPanelProvider } from './SignInPanelContext';
import { SignInReason, SignInTabPanelManager } from './SignInTabPanelManager';
import { ContentWrapper } from './sessionExpiryModal.styles';

export const SessionExpiredTabPanel = ({
  handleCloseModal,
}: ISignInTabPanel) => {
  return (
    <SignInPanelProvider>
      <ContentWrapper>
        <SignInTabPanelManager
          signInReason={SignInReason.SESSION_EXPIRED}
          onCompleted={handleCloseModal}
        />
      </ContentWrapper>
    </SignInPanelProvider>
  );
};
