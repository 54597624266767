import { SessionUser } from 'providers/SessionProvider/sessionProvider.types';
import { useEffect, useMemo, useState } from 'react';
import { getPrivateUserAvatarUrl } from './privateUserAvatar';

export const AVATAR_CHANGED_EVENT = 'avatarChanged';
const avatarChangedEvent = new CustomEvent(AVATAR_CHANGED_EVENT);

export const dispatchAvatarChangedEvent = () => {
  window.dispatchEvent(avatarChangedEvent);
};

// This will force the browser to refetch the image when the timestamp changes
export const getAvatarUrlWithTimestamp = (
  user: SessionUser,
  timestamp: number,
) => {
  const timestampQuery = `?${timestamp.toString()}`;

  return (
    getPrivateUserAvatarUrl({
      firebaseId: user.firebaseId,
      institutionId: user.institution._id.toString(),
      assetPath: user.avatar,
    }) + timestampQuery
  );
};

export const useUserAvatar = ({ user }: { user?: SessionUser }) => {
  const [avatarChangedTimestamp, setAvatarChangedTimestamp] = useState<number>(
    Date.now(),
  );

  useEffect(() => {
    const listener = () => setAvatarChangedTimestamp(Date.now());
    window.addEventListener(AVATAR_CHANGED_EVENT, listener);

    return () => window.removeEventListener(AVATAR_CHANGED_EVENT, listener);
  }, []);

  const avatarUrl = useMemo(() => {
    return user?.avatar
      ? getAvatarUrlWithTimestamp(user, avatarChangedTimestamp)
      : undefined;
  }, [user, avatarChangedTimestamp]);

  return avatarUrl;
};
