import { Badge } from '@mui/material';
import { forwardRef } from 'react';

import AppBarIconButton from 'components/system/AppBar/AppBarIconButton';
import { STATIC_ASSETS_URL } from 'constants/api';
import { INotificationButton } from './notifications.types';

const NotificationsButton = forwardRef<HTMLButtonElement, INotificationButton>(
  ({ onClick, newNotifications, ...rest }, ref) => (
    <AppBarIconButton onClick={onClick} edge="start" ref={ref} {...rest}>
      <Badge badgeContent={newNotifications} color="primary">
        <img
          src={`${STATIC_ASSETS_URL}/img/icons/bel.svg`}
          alt="Notifications"
        />
      </Badge>
    </AppBarIconButton>
  ),
);

export default NotificationsButton;
