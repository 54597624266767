export const FeedbackType = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
} as const;

export type TFeedbackType = (typeof FeedbackType)[keyof typeof FeedbackType];

type TFeedbackBtn = {
  label?: string;
  onClick?: () => void | Promise<void>;
};

export interface IFeedbackModal {
  type?: TFeedbackType;
  title?: string;
  message?: string;
  cancelBtn?: TFeedbackBtn;
  submitBtn?: TFeedbackBtn;
  additionalOptions?: TFeedbackBtn[];
}

export interface IFeedbackModalState extends IFeedbackModal {
  isOpen: boolean;
  handleClose: () => void;
}
