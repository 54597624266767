import { PRIVATE_ASSETS_URL } from 'constants/api';
import { ExamObject, UserObject } from 'types/mongoose-types';

export enum PrivateAssetTypeEnum {
  UPLOADS = 'uploads',
  FRAMES = 'frames',
  VIDEOS = 'videos',
  INTERFACE = 'interface',
}

export interface IGetPrivateAssetUrl {
  type: PrivateAssetTypeEnum;
  firebaseId: string;
  institutionId: string;
  assetPath?: string;
}

export const getPrivateAssetUrl = ({
  type,
  firebaseId,
  institutionId,
  assetPath = '',
}: IGetPrivateAssetUrl) => {
  return `${PRIVATE_ASSETS_URL}/${institutionId}/${type}/users/${firebaseId}/${assetPath}`;
};

export interface IGetPrivateExamAssetUrl {
  type: PrivateAssetTypeEnum;
  exam: ExamObject;
  assetPath?: string;
}

export const getPrivateExamAssetUrl = ({
  type,
  exam,
  assetPath = '',
}: IGetPrivateExamAssetUrl) => {
  const examId = exam._id.toString();
  const examAssetPath = `${examId}/${assetPath}`;
  const institutionId = (exam.user as UserObject).institution._id.toString();
  const firebaseId = (exam.user as UserObject).firebaseId;

  return getPrivateAssetUrl({
    type,
    institutionId,
    firebaseId,
    assetPath: examAssetPath,
  });
};
