import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

interface ITooltipProps extends TooltipProps {
  isEnabled: boolean;
}

const Tooltip = ({ isEnabled, ...props }: ITooltipProps) => {
  return (
    <MuiTooltip
      arrow
      enterDelay={300}
      enterNextDelay={0}
      disableFocusListener={isEnabled}
      disableHoverListener={isEnabled}
      disableTouchListener={isEnabled}
      {...props}
    />
  );
};

export default Tooltip;
