import { Box, CircularProgress } from '@mui/material';
import { useCloudflareAuth } from 'components/auth/hooks/useCloudflareAuth';
import {
  StyledMessage,
  StyledTitle,
} from 'components/layout/FeedbackModal/feedbackModal.styles';
import { useSessionExpiryModal } from 'providers';
import { SignInScreen } from '../SignInScreen';

interface ISessionExpiredScreen {
  onCompleted: () => void;
}

export const SessionExpiredScreen = ({
  onCompleted,
}: ISessionExpiredScreen) => {
  const { isModalOpen } = useSessionExpiryModal();
  const { loading } = useCloudflareAuth(isModalOpen, onCompleted);

  return (
    <>
      <StyledTitle variant="h3" align="center">
        Sign In
      </StyledTitle>
      {loading ? (
        <>
          <StyledTitle variant="h3" align="center">
            Authenticating with cloudflare access...
          </StyledTitle>
          <CircularProgress size={64} />
        </>
      ) : (
        <>
          <StyledMessage variant="body1" align="center">
            Your session has expired. Please sign in again.
          </StyledMessage>
          <Box sx={{ alignSelf: 'stretch' }}>
            <SignInScreen onCompleted={onCompleted} />
          </Box>
        </>
      )}
    </>
  );
};
