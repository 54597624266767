import { ChevronLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { SignInStage } from '../SignInPanelContext';
import { useSignInPanelContext } from '../SignInPanelContext/useSignInPanelContext';

export const BackToSignInButton = () => {
  const { goToStage } = useSignInPanelContext();

  return (
    <Button
      sx={{
        textTransform: 'none',
        // Using absolute positioning since parent components are not occupying the full height
        // of the modal, and they go way beyond the modal height if their height is set to 100%
        position: 'absolute',
        bottom: 20,
        right: 20,
        gap: '4px',
        // Adjusting for icon blank space
        paddingLeft: '2px',
      }}
      onClick={() => goToStage(SignInStage.SIGN_IN)}
    >
      <ChevronLeft sx={{ height: '16px', width: '16px' }} />
      Back to login
    </Button>
  );
};
