import { axiosRestApi } from 'config/axiosRestApi';
import { useFeedbackModal } from 'providers';
import { useState } from 'react';
import { logError } from 'utils/logError';

interface IUseResetPasswordProps {
  onCompleted: () => void;
}

export const useResetPassword = (props?: IUseResetPasswordProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openFeedbackModal } = useFeedbackModal();

  const resetPassword = async (email: string) => {
    setIsLoading(true);
    try {
      await axiosRestApi.post('/reset-password', {
        email,
      });

      props?.onCompleted();
    } catch (error) {
      openFeedbackModal({
        type: 'error',
        title: 'Error sending password reset email',
        message: 'Please try again later',
      });
      logError({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, resetPassword };
};
