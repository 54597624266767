import * as Sentry from '@sentry/react';
import SessionManager from 'components/auth/SessionManager/SessionManager';
import SessionExpiryModal from 'components/auth/signIn/SessionExpiryModal';
import PageBase from 'components/layout/PageBase/PageBase';
import Page404 from 'components/pages/Page404/Page404';
import { ContainerLoading } from 'components/system/ContainerLoading';
import { ToastContainer } from 'components/system/Toast';
import { SessionProvider } from 'providers';
import { FormManagerProvider } from 'providers/FormManagerProvider';
import ModalRenderer from 'providers/ModalProvider/ModalRenderer';
import { Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { lazyLoadWithRetry } from 'utils/lazyLoadWithRetry';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_VERSION
    ? `web-app-frontend@${process.env.REACT_APP_VERSION}`
    : undefined,
  enableTracing: process.env.REACT_APP_SENTRY_ENABLE_TRACING === 'true',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const LandingPage = lazyLoadWithRetry(
  () => import('components/layout/LandingPage/LandingPage'),
  'landing-page',
);
const CreateExam = lazyLoadWithRetry(
  () => import('components/pages/CreateExam/CreateExam'),
  'create-exam',
);
const CreatePatient = lazyLoadWithRetry(
  () => import('components/pages/CreatePatient/CreatePatient'),
  'create-patient',
);
const MyAccount = lazyLoadWithRetry(
  () => import('components/pages/MyAccount/MyAccount'),
  'my-account',
);
const LoadingPredictions = lazyLoadWithRetry(
  () => import('components/pages/LoadingPredictions/LoadingPredictions'),
  'loading-predictions',
);
const Dashboard = lazyLoadWithRetry(
  () => import('components/pages/Dashboard/Dashboard'),
  'dashboard',
);
const ValidationView = lazyLoadWithRetry(
  () => import('components/pages/Validation/ValidationView'),
  'validation-view',
);
const PatientsView = lazyLoadWithRetry(
  () => import('components/pages/PatientsView/PatientsView'),
  'patients-view',
);
const StudyManager = lazyLoadWithRetry(
  () => import('components/pages/StudyManager/StudyManager'),
  'study-manager',
);
const About = lazyLoadWithRetry(
  () => import('components/pages/About/About'),
  'about',
);
const SearchPatient = lazyLoadWithRetry(
  () => import('components/pages/CreateExam/SearchPatient/SearchPatient'),
  'search-patient',
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <FormManagerProvider>
          <SessionProvider>
            <SessionManager />
            <SessionExpiryModal />
            <ModalRenderer />
            <ToastContainer />
            <Outlet />
          </SessionProvider>
        </FormManagerProvider>
      }
    >
      <Route
        path="/"
        element={
          <Suspense fallback={<ContainerLoading />}>
            <LandingPage />
          </Suspense>
        }
      />

      <Route path="app" element={<PageBase />}>
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="my-account"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <MyAccount />
            </Suspense>
          }
        />
        <Route path="create-ai-exam/*">
          <Route
            path=":patientId"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <CreateExam />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <SearchPatient />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="create-patient"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <CreatePatient />
            </Suspense>
          }
        />
        <Route
          path="loading-predictions"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <LoadingPredictions />
            </Suspense>
          }
        />
        <Route path="validation/*">
          <Route
            path=":examId/:notificationId"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <ValidationView />
              </Suspense>
            }
          />
          <Route
            path=":examId/*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <ValidationView />
              </Suspense>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Route>

        <Route path="patients-view/*">
          <Route
            path=":patientId/*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <PatientsView />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <PatientsView />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="study-manager"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <StudyManager />
            </Suspense>
          }
        />

        <Route
          path="about"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <About />
            </Suspense>
          }
        />
      </Route>

      <Route path="*" element={<Page404 />} />
    </Route>,
  ),
);
