import { Box, Typography } from '@mui/material';

import { STATIC_ASSETS_URL } from 'constants/api';
import { DEVICES } from 'constants/products';
import { colors } from 'style/colors';
import {
  DropZone,
  DropZoneContainer,
  DropZoneItem,
  DropZoneList,
  ErrorHelperText,
  LabelWrapper,
} from './examDropZone.styles';
import { IExamDropZone } from './examDropZone.types';
import { formatAcceptedFilesMessage } from './helpers/formatAcceptedFilesMessage';

const ExamDropZone = ({ exam, register, files, error }: IExamDropZone) => {
  const deviceInfo = DEVICES.find(({ id }) => id === exam?.device);
  const file = files ? Object.values(files)[0] : null;
  const acceptedExtensionsMessage = formatAcceptedFilesMessage(
    deviceInfo?.acceptedFiles,
  );

  return (
    <DropZoneContainer>
      <DropZone
        component="label"
        aria-label="Upload file"
        hasError={Boolean(error)}
      >
        <input
          {...register('files')}
          accept={deviceInfo?.acceptedFiles}
          type="file"
          className="inputfile"
          autoFocus
          data-testid="dropzone-input"
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" py="16px">
            <LabelWrapper>
              {!file ? (
                <>
                  <img
                    src={`${STATIC_ASSETS_URL}/img/icons/color-upload.svg`}
                    alt="File Upload"
                  />
                  {'Click to upload file'}
                  <Typography sx={{ color: colors.grey1 }}>
                    {acceptedExtensionsMessage}
                  </Typography>
                </>
              ) : (
                <Box display="flex" flexDirection="column">
                  <span>{'Selected file:'}</span>
                  <DropZoneList>
                    <DropZoneItem>{file.name}</DropZoneItem>
                  </DropZoneList>
                </Box>
              )}
            </LabelWrapper>
          </Box>
          <ErrorHelperText>{error && error.message}</ErrorHelperText>
        </Box>
      </DropZone>
    </DropZoneContainer>
  );
};

export default ExamDropZone;
