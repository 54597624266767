import { Tooltip } from '@mui/material';

import { useFeedbackModal } from 'providers';
import NotificationsButton from './NotificationsButton';
import NotificationsMenu from './NotificationsMenu';
import { useNotifications } from './notifications.hooks';

const Notifications = () => {
  const {
    anchorEl,
    areNotificationsOpen,
    handleMenuButtonClick,
    handleClose,
    newNotifications,
    notifications,
    updateNotification,
    removeNotification,
  } = useNotifications();
  const { openFeedbackModal } = useFeedbackModal();

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        enterDelay={300}
        enterNextDelay={0}
        title="Notifications"
      >
        <NotificationsButton
          onClick={handleMenuButtonClick}
          newNotifications={newNotifications}
        />
      </Tooltip>
      <NotificationsMenu
        anchorEl={anchorEl}
        isOpen={areNotificationsOpen}
        onClose={handleClose}
        notifications={notifications}
        openFeedbackModal={openFeedbackModal}
        updateNotification={updateNotification}
        removeNotification={removeNotification}
      />
    </>
  );
};

export default Notifications;
