import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useSessionExpiryModal } from 'providers/SessionExpiryModalProvider/SessionExpiryModalProvider';
import { router } from 'routes/router';
import { logError } from 'utils/logError';
import { initApollo } from './apollo';
import './App.css';
import { useFeedbackModal } from './providers';
import theme from './style';

const App = () => {
  const { openFeedbackModal } = useFeedbackModal();
  const { openSessionExpiryModal } = useSessionExpiryModal();

  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();
  const [, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    (async () => {
      const { newClient, newPersistor } = await initApollo(
        openFeedbackModal,
        openSessionExpiryModal,
      );
      setApolloClient(newClient);
      setPersistor(newPersistor);
    })().catch((error: unknown) => {
      logError({ error });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.theme = theme('dark');
  // default locale is en-us (currentLocale = undefined)

  if (!apolloClient) {
    return <h2>Initializing app...</h2>;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};

export default App;
