import { ComponentType, lazy } from 'react';
import { logError } from './logError';

type TComponent = {
  default: ComponentType<any>;
};

type TLoadFn = () => Promise<TComponent>;

// The `name` allows us to retry loading a lazy loaded component that is nested inside
// another lazy loaded component by giving them separate session storage keys
const retryLoad = (load: TLoadFn, name: string): TLoadFn => {
  const sessionStorageKey = `retry-${name}-refreshed`;

  return () =>
    new Promise<TComponent>((resolve, reject) => {
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(sessionStorageKey) || 'false',
      ) as boolean;

      load()
        .then((component) => {
          window.sessionStorage.setItem(sessionStorageKey, 'false');
          resolve(component);
        })
        .catch((error: unknown) => {
          if (!hasRefreshed) {
            window.sessionStorage.setItem(sessionStorageKey, 'true');
            return window.location.reload();
          }

          // If we have refreshed already and still have an error,
          // the page will crash (so we don't have an infinite loop)
          logError({ error });
          reject(error);
        });
    });
};

export const lazyLoadWithRetry = (load: TLoadFn, name: string) =>
  lazy(retryLoad(load, name));
