import {
  ApolloClient,
  ApolloError,
  MutationFunctionOptions,
  ServerError,
  useMutation,
  useQuery,
} from '@apollo/client';

import { GET_RECENT_ACTIVITY } from 'components/pages/Dashboard/RecentTable/recentTable.api';
import { GET_PATIENT_DETAILS } from 'components/pages/PatientsView/PatientDetails/PatientDetails.api';
import { GET_PATIENT_EXAMS } from 'components/pages/PatientsView/PatientDetails/PatientExams/PatientExams.api';
import { GET_USER_EXAMS } from 'components/pages/StudyManager/studyManager.api';
import { displayToast } from 'components/system/Toast';
import { ToastTypeEnum } from 'components/system/Toast/toast.constants';
import { ExamStatus } from 'constants/exams';
import { useSession } from 'providers';
import { useState } from 'react';
import { TOperationProps } from 'types/apollo-types';
import { ExamObject } from 'types/mongoose-types';
import {
  CREATE_EXAM,
  GET_PATIENT_DRAFT_EXAMS,
  REMOVE_EXAM,
} from './examPanel.api';

type TCreateExamData = {
  createExam: {
    record: {
      _id?: ExamObject['_id'];
      user?: ExamObject['user'];
      patient?: ExamObject['patient'];
      technology?: ExamObject['technology'];
      device?: ExamObject['device'];
    };
  };
};

type TRemoveExamData = {
  removeExam: {
    _id?: ExamObject['_id'];
  };
};

export const useGetDraftExams = (patientId?: string) => {
  const [draftExams, setDraftExams] = useState<ExamObject[]>([]);
  const { user } = useSession();

  const { loading, error } = useQuery(GET_PATIENT_DRAFT_EXAMS, {
    skip: !patientId,
    variables: {
      examsFilter: {
        AND: [
          {
            user: user?._id.toString(),
            patient: patientId,
            status: ExamStatus.Draft,
          },
        ],
      },
    },
    onCompleted: ({ exams }: { exams: ExamObject[] }) =>
      setDraftExams([...exams]),
  });

  return { draftExams, loading, error };
};

export const useCreateExam = (
  options?: MutationFunctionOptions<TCreateExamData>,
) => {
  const [createExam, mutationData]: [
    TOperationProps,
    {
      data?: TCreateExamData;
      loading?: boolean;
      error?: ApolloError | ServerError;
    },
  ] = useMutation(CREATE_EXAM, options);

  const { data, loading, error } = mutationData;

  return { createExam, data, loading, error };
};

export const useRemoveExam = () => {
  const [removeExam, mutationData]: [
    TOperationProps<TRemoveExamData>,
    {
      data?: TRemoveExamData | null;
      loading?: boolean;
      error?: ApolloError | ServerError;
      client: ApolloClient<object>;
    },
  ] = useMutation(REMOVE_EXAM, {
    refetchQueries: [
      GET_RECENT_ACTIVITY,
      GET_USER_EXAMS,
      GET_PATIENT_EXAMS,
      GET_PATIENT_DETAILS,
    ],
    onCompleted: (response: TRemoveExamData) => {
      if (response.removeExam) {
        displayToast({
          type: ToastTypeEnum.SUCCESS,
          title: 'Exam deleted',
          description: 'The exam was successfully deleted.',
        });
      }
    },
    onError: (responseError: ApolloError) => {
      const errorMessage = responseError.graphQLErrors[0].extensions?.message;

      displayToast({
        type: ToastTypeEnum.ERROR,
        title: 'Error deleting exam',
        description:
          errorMessage && typeof errorMessage === 'string'
            ? errorMessage
            : 'Please try again later.',
      });
    },
  });

  const { data, loading, error } = mutationData;
  return { removeExam, data, loading, error };
};
