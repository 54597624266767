import dayjs, { ConfigType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDateWithTimezone = (date?: ConfigType) => {
  return dayjs.utc(date).tz();
};
