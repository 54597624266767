import { Avatar, Badge, IconButton, styled } from '@mui/material';
import { useSession } from 'providers';
import { forwardRef, MouseEventHandler } from 'react';
import { useUserAvatar } from 'utils/avatarChangeEvent';

import { getUserInitials } from 'utils/getUserInitials';

interface IStatusButton {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: '20px',
  '& > img': {
    width: '25px',
    height: '25px',
  },
  '& .MuiBadge-badge': {
    transform: 'scale(1) translate(40%, -30%)',
  },
  '& .MuiAvatar-root': {
    fontSize: '14px',
    width: '30px',
    height: '30px',
    border: `1px solid ${theme.palette.primary.main}`,
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
}));

const StatusButton = forwardRef<HTMLButtonElement, IStatusButton>(
  ({ onClick, ...rest }, ref) => {
    const { user } = useSession();
    const avatarUrl = useUserAvatar({ user });

    return (
      <CustomIconButton onClick={onClick} edge="start" ref={ref} {...rest}>
        <Badge
          color="success"
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar src={avatarUrl} alt="Status">
            {user && getUserInitials(user)}
          </Avatar>
        </Badge>
      </CustomIconButton>
    );
  },
);

export default StatusButton;
