import { Box } from '@mui/material';
import { FC } from 'react';

import SubmitButton from 'components/system/buttons/SubmitButton';
import ContainerLoading from 'components/system/ContainerLoading/ContainerLoading';
import { FormTextField } from 'components/system/form';
import { PasswordFormField } from '../../PasswordFormField';
import { SignInStage, useSignInPanelContext } from '../SignInPanelContext';
import { ChangeAccountButton } from './ChangeAccountButton';
import { useSignIn } from './useSignIn';

interface ISignInScreen {
  onCompleted: () => void;
}

export const SignInScreen: FC<ISignInScreen> = ({ onCompleted }) => {
  const { goToStage, prefilledEmail } = useSignInPanelContext();
  const { isLoading, formHandleSubmit, errors, register } = useSignIn({
    onCompleted,
  });

  return isLoading ? (
    <ContainerLoading />
  ) : (
    <form onSubmit={formHandleSubmit} noValidate>
      <Box display="flex" flexDirection="column" paddingX={8} paddingY={3}>
        <FormTextField
          id="email"
          label="Email"
          variant="standard"
          error={errors.email?.message}
          required
          disabled={!!prefilledEmail}
          {...register('email')}
        />
        <PasswordFormField
          onForgotPassword={() => goToStage(SignInStage.RESET_PASSWORD)}
          id="password"
          label="Password"
          variant="standard"
          error={errors.password?.message}
          required
          {...register('password')}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ width: 'fit-content', margin: 'auto' }}
      >
        <SubmitButton
          type="submit"
          variant="contained"
          sx={{ alignSelf: 'stretch' }}
        >
          SUBMIT
        </SubmitButton>
        {prefilledEmail ? <ChangeAccountButton /> : null}
      </Box>
    </form>
  );
};
