const formatAcceptedFilesArray = (acceptedFiles: string[]): string => {
  const uppercaseFileExtensions = acceptedFiles.map((fileExtension) =>
    fileExtension.toUpperCase(),
  );
  if (uppercaseFileExtensions.length === 0) return '';
  if (uppercaseFileExtensions.length === 1) return uppercaseFileExtensions[0];

  const lastItem = uppercaseFileExtensions.pop() as string;

  return `${uppercaseFileExtensions.join(', ')} or ${lastItem}`;
};

export const formatAcceptedFilesMessage = (acceptedFiles?: string) => {
  const acceptedFilesArray = acceptedFiles?.split(',') || [];
  return acceptedFiles
    ? `Your video should be in format ${formatAcceptedFilesArray(
        acceptedFilesArray,
      )} and shouldn't be bigger than 5GB.`
    : "Your video shouldn't be bigger than 5GB.";
};
