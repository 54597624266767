import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      _id
      firebaseId
      email
      firstName
      lastName
      avatar
      timezone
      institution {
        _id
        name
        dataAccessScope
      }
    }
  }
`;
