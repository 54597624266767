import { Box, Typography } from '@mui/material';
import { SubmitButton } from 'components/system/buttons';
import { ContainerLoading } from 'components/system/ContainerLoading';
import { FormTextField } from 'components/system/form';
import { colors } from 'style/colors';
import { useSignInPanelContext } from '../SignInPanelContext';
import { BackToSignInButton } from './BackToSignInButton';
import { useResetPasswordForm } from './useResetPasswordForm';

export const ResetPasswordScreen = () => {
  const { prefilledEmail } = useSignInPanelContext();
  const { isLoading, formHandleSubmit, errors, register } =
    useResetPasswordForm();

  return (
    <Box sx={{ textAlign: 'center', color: colors.white1 }}>
      <Typography variant="h4" component="h1" sx={{ marginBottom: '8px' }}>
        Forgot Password?
      </Typography>
      <Typography sx={{ color: colors.grey1 }}>
        No worries, we'll send you reset instructions.
      </Typography>
      {isLoading ? (
        <ContainerLoading />
      ) : (
        <Box>
          <form onSubmit={formHandleSubmit} noValidate>
            <Box paddingX={8} paddingY={3}>
              <FormTextField
                id="email"
                label="Email"
                variant="standard"
                error={errors.email?.message}
                required
                disabled={!!prefilledEmail}
                sx={{ minWidth: '220px' }}
                {...register('email')}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <SubmitButton type="submit" variant="contained">
                Reset password
              </SubmitButton>
            </Box>
          </form>
        </Box>
      )}
      <BackToSignInButton />
    </Box>
  );
};
