import {
  ApolloError,
  LazyQueryExecFunction,
  MutationFunctionOptions,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { GET_PATIENT_EXAM_DETAILS } from 'components/pages/PatientsView/PatientDetails/PatientExamsDetails/PatientExamDetails.api';
import { displayToast } from 'components/system/Toast';
import { Types } from 'mongoose';
import { useForm } from 'react-hook-form';
import { TOperationProps } from 'types/apollo-types';
import { getDateWithTimezone } from 'utils/date/getDateWithTimezone';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { GET_EXAM_STATUS, UPDATE_EXAM } from './examForms.api';
import {
  IExamFormHookProps,
  IExamStatusQueryData,
  IFormFieldValues,
  IUpdateExamMutationData,
  TExamStatusData,
  TUpdateExamData,
  TUpdateExamResponse,
} from './examForms.types';
import { getValidationSchema } from './examForms.yup';

export const onUpdateExamError = (responseError: ApolloError) => {
  const errorMessage = responseError?.graphQLErrors?.[0]?.extensions?.message;

  displayToast({
    type: 'error',
    title: 'Error updating exam',
    description:
      errorMessage && typeof errorMessage === 'string'
        ? errorMessage
        : 'Please try again later.',
  });
};

export const useUpdateExam = (
  examId?: Types.ObjectId,
  options?: MutationFunctionOptions<TUpdateExamData>,
) => {
  const refetchQueries = examId && [
    { query: GET_PATIENT_EXAM_DETAILS, variables: { examId } },
  ];
  const [updateExam, mutationData]: [TOperationProps, IUpdateExamMutationData] =
    useMutation(UPDATE_EXAM, {
      onError: onUpdateExamError,
      refetchQueries,
      ...options,
    });

  const { data, loading, error } = mutationData;
  return { updateExam, data, loading, error };
};

export const useExamStatus = () => {
  const [getExamStatus, queryData]: [
    LazyQueryExecFunction<TExamStatusData, OperationVariables>,
    IExamStatusQueryData,
  ] = useLazyQuery(GET_EXAM_STATUS);

  const { data, loading, error } = queryData;

  return { getExamStatus, data, loading, error };
};

export const useExamForm = ({
  exam,
  onCompleteSubmission,
  onBeforeSubmission,
  nextStatus,
}: IExamFormHookProps) => {
  const formReturn = useForm<IFormFieldValues>({
    mode: 'onBlur',
    resolver: useYupValidationResolver(getValidationSchema()),
  });

  const {
    updateExam,
    loading,
    error: updateExamError,
  } = useUpdateExam(exam?._id);

  const { handleSubmit } = formReturn;

  const onSubmit = async (formData: IFormFieldValues) => {
    onBeforeSubmission?.();
    const examDate = formData.examDate
      ? getDateWithTimezone(formData.examDate).set('hour', 12).toDate()
      : null;
    const informationData = {
      ...formData,
      examDate,
      physician: formData.physician?.value,
      priority: formData.priority?.value || '',
      ...(nextStatus && { status: nextStatus }),
    };

    // update exam in database
    await updateExam({
      variables: {
        updateExamId: exam?._id,
        updateExamRecord: informationData,
      },
      onCompleted: (data: TUpdateExamResponse) => {
        void onCompleteSubmission(data);
      },
    });
  };

  return {
    isLoading: loading,
    handleSubmit: handleSubmit(onSubmit),
    formReturn,
    updateExamError,
  };
};
