import { useSessionExpiryModal } from 'providers/SessionExpiryModalProvider/SessionExpiryModalProvider';
import { SessionExpiredTabPanel } from './SessionExpiredTabPanel';
import { LogoModalDarkBackdrop } from './sessionExpiryModal.styles';

const SessionExpiryModal = () => {
  const { isModalOpen, closeSessionExpiryModal } = useSessionExpiryModal();

  const onClose = (_event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    } else {
      closeSessionExpiryModal();
    }
  };

  return (
    <LogoModalDarkBackdrop
      hideBackdrop={false}
      openModal={isModalOpen}
      handleCloseModal={closeSessionExpiryModal}
      disableClose
      disableEscapeKeyDown
      onClose={onClose}
    >
      <SessionExpiredTabPanel handleCloseModal={closeSessionExpiryModal} />
    </LogoModalDarkBackdrop>
  );
};

export default SessionExpiryModal;
