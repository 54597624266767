import CloseIcon from '@mui/icons-material/Close';
import { FC, useCallback } from 'react';

import { OutlinedButton, SubmitButton } from 'components/system/buttons';
import { renderIconContent } from './feedbackModal.helper';
import {
  ButtonsWrapper,
  CloseButton,
  ContentWrapper,
  IconWrapper,
  StyledMessage,
  StyledModal,
  StyledTitle,
} from './feedbackModal.styles';
import { IFeedbackModalState } from './feedbackModal.types';

const FeedbackModal: FC<IFeedbackModalState> = ({
  type,
  isOpen,
  handleClose,
  title,
  message,
  cancelBtn,
  submitBtn,
  additionalOptions,
}) => {
  const handleClick = useCallback(
    (method?: () => void) => {
      if (method) method();
      handleClose();
    },
    [handleClose],
  );

  const onClose = useCallback(() => {
    handleClick(cancelBtn?.onClick);
  }, [cancelBtn?.onClick, handleClick]);

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <ContentWrapper>
        <IconWrapper>{renderIconContent(type)}</IconWrapper>

        <StyledTitle variant="h3" align="center">
          {title}
        </StyledTitle>
        <StyledMessage variant="body1" align="center">
          {message}
        </StyledMessage>

        <ButtonsWrapper oneBtnOnly={!submitBtn && !additionalOptions}>
          <OutlinedButton
            key="cancel"
            variant="outlined"
            onClick={() => handleClick(cancelBtn?.onClick)}
          >
            {cancelBtn?.label ?? 'OK'}
          </OutlinedButton>
          {!!additionalOptions?.length &&
            additionalOptions.map((option, index) => (
              <OutlinedButton
                key={index}
                variant="outlined"
                onClick={() => handleClick(option.onClick)}
              >
                {option.label}
              </OutlinedButton>
            ))}
          {!!submitBtn && (
            <SubmitButton
              key="submit"
              onClick={() => handleClick(submitBtn.onClick)}
              variant="contained"
            >
              {submitBtn.label ?? 'CONFIRM'}
            </SubmitButton>
          )}
        </ButtonsWrapper>

        <CloseButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </CloseButton>
      </ContentWrapper>
    </StyledModal>
  );
};

export default FeedbackModal;
