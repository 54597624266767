const FIVE_GB_IN_BYTES = 5 * 1024 * 1024 * 1024;

export const validateFileSize = (files: FileList | undefined) => {
  if (!files) {
    return false;
  }
  const filesArray = Array.from(files);

  const hasInvalidFileSize = filesArray.some((file: File) => {
    return file.size > FIVE_GB_IN_BYTES;
  });

  return !hasInvalidFileSize;
};
