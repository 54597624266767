import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { FeedbackType, TFeedbackType } from './feedbackModal.types';

export const renderIconContent = (type?: TFeedbackType) => {
  switch (type) {
    case FeedbackType.Error:
      return <CancelOutlinedIcon />;
    case FeedbackType.Info:
      return <InfoOutlinedIcon />;
    case FeedbackType.Success:
      return <DoneIcon />;
    case FeedbackType.Warning:
      return <WarningAmberIcon />;
    default:
      return <InfoOutlinedIcon />;
  }
};
