import { IconButton } from '@mui/material';
import { FC } from 'react';

import { useLogout } from 'components/auth/hooks/useLogout';
import ListItemLink from 'components/system/ListItemLink/ListItemLink';
import { Tooltip } from 'components/system/Tooltip';
import { STATIC_ASSETS_URL } from 'constants/api';
import { PAGE_TITLES } from '../PageBase/constants';
import {
  AppVersionText,
  CustomDrawer,
  CustomList,
  CustomListItemIcon,
  CustomListItemText,
  CustomListLast,
  LastWrapper,
  ListLogo,
  LogoWrapper,
  MainLogo,
} from './appDrawer.styles';

interface IAppDrawer {
  open: boolean;
  handleDrawerToggle: () => void;
  activePage: string;
}

export const DRAWER_OPTIONS: { url: keyof typeof PAGE_TITLES; icon: string }[] =
  [
    { url: 'dashboard', icon: 'dashboard.svg' },
    { url: 'my-account', icon: 'account.svg' },
    { url: 'create-ai-exam', icon: 'ai-exam.svg' },
    { url: 'create-patient', icon: 'create-patient.svg' },
    { url: 'study-manager', icon: 'study-manager.svg' },
    { url: 'patients-view', icon: 'patient-view.svg' },
  ];

const AppDrawer: FC<IAppDrawer> = ({
  open,
  handleDrawerToggle,
  activePage,
}) => {
  const { logout } = useLogout();

  return (
    <CustomDrawer variant="permanent" anchor="left" open={open}>
      <LogoWrapper>
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          onClick={handleDrawerToggle}
          edge="start"
          size="large"
        >
          <MainLogo
            src={`${STATIC_ASSETS_URL}/img/icon.svg`}
            alt="Digestaid Icon"
          />
        </IconButton>
      </LogoWrapper>
      <CustomList>
        {DRAWER_OPTIONS.map(({ url, icon }) => ({
          url,
          icon: `${STATIC_ASSETS_URL}/img/icons/${
            activePage === url ? 'color-' : ''
          }${icon}`,
        })).map(({ url, icon }) => {
          const text = PAGE_TITLES[url];

          return (
            <ListItemLink to={`/app/${url}`} key={url}>
              <CustomListItemIcon>
                <Tooltip placement="right" title={text} isEnabled={open}>
                  <IconButton aria-label={text} edge="start" size="large">
                    <ListLogo src={icon} alt={text} />
                  </IconButton>
                </Tooltip>
              </CustomListItemIcon>
              <CustomListItemText
                primary={text}
                isActive={activePage === url}
              />
            </ListItemLink>
          );
        })}
      </CustomList>

      <LastWrapper open={open}>
        <AppVersionText open={open}>
          Version {process.env.REACT_APP_VERSION}
        </AppVersionText>
        <CustomListLast>
          <ListItemLink
            to="/"
            key={'Logout'}
            onClick={(event) => {
              event.preventDefault();
              logout();
            }}
          >
            <CustomListItemIcon>
              <Tooltip placement="right" title="Logout" isEnabled={open}>
                <IconButton edge="start" size="large">
                  <ListLogo
                    src={`${STATIC_ASSETS_URL}/img/icons/log-out.svg`}
                    alt="Logout"
                  />
                </IconButton>
              </Tooltip>
            </CustomListItemIcon>
            <CustomListItemText primary={'Logout'} />
          </ListItemLink>
        </CustomListLast>
      </LastWrapper>
    </CustomDrawer>
  );
};

export default AppDrawer;
