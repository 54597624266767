import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

import { useSession, useSessionExpiryModal } from 'providers';
import { logError } from 'utils/logError';
import { useLogout } from '../hooks/useLogout';

const TIME_TO_EXPIRE =
  1000 * 60 * (Number(process.env.REACT_APP_INACTIVITY_EXPIRATION) ?? 5);

export const useIdleSessionExpiration = () => {
  const apolloClient = useApolloClient();
  const { firebaseId, clearSessionUser } = useSession();
  const { openSessionExpiryModal, isModalOpen } = useSessionExpiryModal();
  const { pathname } = useLocation();
  const { expireSession } = useLogout();

  const isLoginPage = pathname === '/';

  const handleIdle = () => {
    if (!firebaseId) {
      return;
    }

    if (isLoginPage) {
      clearSessionUser().catch((error: unknown) => {
        logError({ error });
      });
    }

    void expireSession();

    if (!isModalOpen) {
      openSessionExpiryModal();
    }

    void apolloClient.clearStore();
  };

  const { start, pause } = useIdleTimer({
    timeout: TIME_TO_EXPIRE,
    onIdle: handleIdle,
    startManually: true,
    crossTab: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (firebaseId) {
      start();
      return;
    }

    pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseId]);
};
