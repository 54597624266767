import { useResetPassword } from 'components/auth/hooks/useResetPassword';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { SignInStage, useSignInPanelContext } from '../SignInPanelContext';
import { validationSchema } from './resetPassword.validation';

interface IResetPasswordFormValues {
  email: string;
}

export const useResetPasswordForm = () => {
  const { goToStage, prefilledEmail } = useSignInPanelContext();

  const { isLoading, resetPassword } = useResetPassword({
    onCompleted: () => goToStage(SignInStage.CONFIRMATION_SENT),
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<IResetPasswordFormValues>({
    mode: 'onBlur',
    defaultValues: { email: prefilledEmail || '' },
    resolver: useYupValidationResolver(validationSchema),
  });

  const formHandleSubmit = handleSubmit(({ email }) => resetPassword(email));

  return {
    isLoading,
    formHandleSubmit,
    errors,
    isValid,
    register,
    setValue,
  };
};
