import { createContext } from 'react';

export const SignInStage = {
  SIGN_IN: 'signIn',
  RESET_PASSWORD: 'resetPassword',
  CONFIRMATION_SENT: 'confirmationSent',
} as const;

export type TSignInStage = (typeof SignInStage)[keyof typeof SignInStage];

interface ISignInPanelContext {
  goToStage: (screen: TSignInStage) => void;
  currentStage: TSignInStage;
  prefilledEmail?: string;
}

export const SignInPanelContext = createContext<ISignInPanelContext>({
  goToStage: () => undefined,
  currentStage: SignInStage.SIGN_IN,
});
