import { Path } from 'react-router-dom';

export const SIGN_IN_ROUTE: Partial<Path> = { pathname: '/', hash: '#sign-in' };
export const SIGN_UP_ROUTE: Partial<Path> = { pathname: '/', hash: '#sign-up' };

export const DASHBOARD_ROUTE = '/app/dashboard/';
export const CREATE_AI_EXAM_ROUTE = '/app/create-ai-exam/';
export const CREATE_PATIENT_ROUTE = '/app/create-patient/';
export const STUDY_MANAGER_ROUTE = '/app/study-manager/';
export const PATIENTS_VIEW_ROUTE = '/app/patients-view/';

export const LOADING_PREDICTIONS_ROUTE = '/app/loading-predictions';
