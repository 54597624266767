import { Box, Button, styled, Typography } from '@mui/material';
import { colors } from 'style/colors';
import { SignInStage, useSignInPanelContext } from '../SignInPanelContext';

const ClickHereButton = styled(Button)({
  padding: 0,
  textTransform: 'none',
  textDecoration: 'underline',
  verticalAlign: 'baseline',
  '&:hover': { textDecoration: 'underline' },
  '&:active': { textDecoration: 'underline' },
});

export const ConfirmationSentScreen = () => {
  const { goToStage } = useSignInPanelContext();

  const renderClickHereButton = () => (
    <ClickHereButton
      variant="text"
      onClick={() => goToStage(SignInStage.RESET_PASSWORD)}
    >
      Click here
    </ClickHereButton>
  );

  return (
    <Box>
      <Box sx={{ textAlign: 'center', color: colors.white1 }}>
        <Typography variant="h4" component="h1" sx={{ marginBottom: '22px' }}>
          Check your email
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <Typography sx={{ color: colors.grey1 }}>
            We have sent password recovery instructions to the email you
            provided.
          </Typography>
          <Button
            variant="contained"
            sx={{ alignSelf: 'center' }}
            onClick={() => goToStage(SignInStage.SIGN_IN)}
          >
            Back to login
          </Button>
          <Typography sx={{ color: colors.grey1 }}>
            Didn't receive the email? {renderClickHereButton()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
