import { PAGE_TITLES } from './constants';

const isPageTitleKey = (page: string): page is keyof typeof PAGE_TITLES => {
  return Object.keys(PAGE_TITLES).includes(page);
};

export const getPageTitle = (activePage: string) => {
  if (isPageTitleKey(activePage)) {
    return PAGE_TITLES[activePage];
  }

  return '';
};
