import { OutlinedButton } from 'components/system/buttons';
import { useSession, useSessionExpiryModal } from 'providers';
import { useNavigate } from 'react-router-dom';
import { SIGN_IN_ROUTE } from 'routes/constants';
import { logError } from 'utils/logError';

export const ChangeAccountButton = () => {
  const { closeSessionExpiryModal } = useSessionExpiryModal();
  const { clearSessionUser } = useSession();
  const navigate = useNavigate();

  return (
    <OutlinedButton
      onClick={() => {
        clearSessionUser().catch((error: unknown) => logError({ error }));

        closeSessionExpiryModal();
        navigate(SIGN_IN_ROUTE);
      }}
    >
      Change account
    </OutlinedButton>
  );
};
