import { ConfirmationSentScreen } from './ConfirmationSentScreen';
import { ResetPasswordScreen } from './ResetPasswordScreen';
import { SessionExpiredScreen } from './SessionExpiredScreen';
import { SignInStage, useSignInPanelContext } from './SignInPanelContext';
import { SignInScreen } from './SignInScreen';

export const SignInReason = {
  SIGN_IN: 'signIn',
  SESSION_EXPIRED: 'sessionExpired',
};

export type TSignInReason = (typeof SignInReason)[keyof typeof SignInReason];

interface ISignInTabPanelManager {
  onCompleted: () => void;
  signInReason: TSignInReason;
}

export const SignInTabPanelManager = ({
  onCompleted,
  signInReason,
}: ISignInTabPanelManager) => {
  const { currentStage } = useSignInPanelContext();

  if (currentStage === SignInStage.RESET_PASSWORD) {
    return <ResetPasswordScreen />;
  }

  if (currentStage === SignInStage.CONFIRMATION_SENT) {
    return <ConfirmationSentScreen />;
  }

  if (signInReason === SignInReason.SESSION_EXPIRED) {
    return <SessionExpiredScreen onCompleted={onCompleted} />;
  }

  return <SignInScreen onCompleted={onCompleted} />;
};
