import { Button, FormHelperText, FormHelperTextProps } from '@mui/material';
import {
  FormTextField,
  IFormTextField,
} from 'components/system/form/FormTextField';
import { forwardRef } from 'react';
import { colors } from 'style/colors';

interface IPasswordFormField extends IFormTextField {
  onForgotPassword: () => void;
}

export const PasswordFormField = forwardRef<
  HTMLInputElement,
  IPasswordFormField
>(({ onForgotPassword, ...props }: IPasswordFormField, ref) => {
  return (
    <FormTextField
      type="password"
      autoComplete="off"
      {...props}
      ref={ref}
      FormHelperTextProps={{
        component: ({
          children,
          sx,
          ...componentProps
        }: FormHelperTextProps) => {
          return (
            <FormHelperText
              {...componentProps}
              sx={{
                ...sx,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                gap: '8px',
                width: '100%',
              }}
            >
              <Button
                variant="text"
                sx={{
                  alignSelf: 'start',
                  textTransform: 'capitalize',
                  padding: '0',
                  minWidth: 'unset',
                  fontSize: '10px',
                  color: colors.white1,
                  textWrap: 'nowrap',
                }}
                onClick={onForgotPassword}
              >
                Forgot password?
              </Button>
              {children}
            </FormHelperText>
          );
        },
      }}
    />
  );
});
