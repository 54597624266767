import { isValidRegionVariant } from 'utils/isValidRegionVariant';
import { REGION_VARIANTS } from './region';

export const REGION_VARIANT = isValidRegionVariant(
  process.env.REACT_APP_REGION_VARIANT,
)
  ? process.env.REACT_APP_REGION_VARIANT
  : REGION_VARIANTS.US;

export const isUSRegion = () => REGION_VARIANT === REGION_VARIANTS.US;

export const CLINICAL_STUDY = process.env.REACT_APP_CLINICAL_STUDY === 'true';
