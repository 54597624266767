import { ReactNode } from 'react';
import { SignInPanelContext } from './SignInPanelContext';
import { useSignInPanelProvider } from './useSignInPanelProvider';

export const SignInPanelProvider = ({ children }: { children: ReactNode }) => {
  const context = useSignInPanelProvider();

  return (
    <SignInPanelContext.Provider value={context}>
      {children}
    </SignInPanelContext.Provider>
  );
};
