import { axiosRestApi } from 'config/axiosRestApi';
import { useSession } from 'providers';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { logError } from 'utils/logError';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { ISignInValues } from '../../auth.types';
import { useAuthModalError } from '../../hooks/useAuthModalError';
import { useSignInPanelContext } from '../SignInPanelContext';
import { validationSchema } from './signIn.validation';

interface IUseSignInProps {
  onCompleted: () => void;
}

export const useSignIn = ({ onCompleted }: IUseSignInProps) => {
  const { prefilledEmail } = useSignInPanelContext();
  const [isLoading, setIsLoading] = useState(false);
  const { displayAuthErrorModal, loading } = useAuthModalError();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<ISignInValues>({
    mode: 'onBlur',
    defaultValues: { email: prefilledEmail || '', password: '' },
    resolver: useYupValidationResolver(validationSchema),
  });
  const { updateFirebaseId, updateExpiresIn } = useSession();

  useEffect(() => {
    if (prefilledEmail) {
      setValue('email', prefilledEmail || '');
    }
  }, [setValue, prefilledEmail]);

  const onSubmit = async (values: ISignInValues) => {
    const { email, password } = values;
    setIsLoading(true);
    try {
      const { data } = await axiosRestApi.post<{
        status: string;
        expirationDate: number;
        firebaseId: string;
      }>('/login', {
        email,
        password,
      });

      updateExpiresIn(data.expirationDate.toString());
      updateFirebaseId(data.firebaseId);
      onCompleted();
    } catch (error) {
      displayAuthErrorModal(error, 'error', email);
      logError({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const formHandleSubmit = handleSubmit(onSubmit);

  return {
    isLoading: isLoading || loading,
    formHandleSubmit,
    errors,
    isValid,
    register,
    setValue,
  };
};
