import {
  ApolloClient,
  InMemoryCache,
  ServerError,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

import { IFeedbackModal } from 'components/layout/FeedbackModal';
import { API_URL, WS_URL } from 'constants/api';
import { GraphQLFormattedError } from 'graphql';
import { createClient } from 'graphql-ws';
import { logError } from 'utils/logError';

const handledErrors = ['removeExam', 'updateExam', 'validateExam'];
const isHandledError = (error?: GraphQLFormattedError) => {
  return handledErrors.some((handledError) =>
    error?.path?.includes(handledError),
  );
};

// Log any GraphQL errors or network error that occurred
const getErrorLink = (
  openFeedbackModal: (props: IFeedbackModal) => void,
  openSessionExpiryModal: () => void,
) =>
  onError(({ graphQLErrors, networkError }) => {
    const errors = graphQLErrors?.map(
      ({ message, locations, path }) =>
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${JSON.stringify(path)}`,
    );
    if (networkError) {
      if (networkError && (networkError as ServerError).statusCode === 401) {
        openSessionExpiryModal();
      } else if (networkError.message === 'Socket closed') {
        logError({
          error: `[Network error]: ${networkError.message}`,
          ignoreSentry: true,
        });
      } else {
        logError({
          error: `[Network error]: ${JSON.stringify(networkError)}`,
        });
      }
    } else {
      errors?.forEach((error) => logError({ error }));

      if (!isHandledError(graphQLErrors?.[0])) {
        openFeedbackModal({
          type: 'error',
          title: 'Request error',
          message: 'Please try again later.',
        });
      }
    }
  });

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 30000,
  },
  attempts: (count, operation, error) =>
    !!error && operation.operationName !== 'GetUserNotifications' && count < 5,
});

const httpLink = createHttpLink({
  uri: `${API_URL}/api/graphql`,
  credentials:
    process.env.REACT_APP_GRAPHQL_INCLUDE_CREDENTIALS ?? 'same-origin',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${WS_URL}/api/graphql`,
    retryAttempts: 5,
    shouldRetry: () => true,
    on: {
      connected: () => console.log('connected websocket client'),
      closed: () => console.log('closed websocket'),
      error: (err) => console.log('websocket error: ', err),
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const initApollo = async (
  openFeedbackModal: (props: IFeedbackModal) => void,
  openSessionExpiryModal: () => void,
) => {
  const cache = new InMemoryCache({
    addTypename: false,
  });

  const newPersistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    debug: true,
    trigger: 'write',
  });
  await newPersistor.restore();

  const errorLink = getErrorLink(openFeedbackModal, openSessionExpiryModal);

  const newClient = new ApolloClient({
    cache,
    link: from([retryLink, errorLink, splitLink]),
    credentials:
      process.env.REACT_APP_GRAPHQL_INCLUDE_CREDENTIALS ?? 'same-origin',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return { newClient, newPersistor };
};
