import { gql } from '@apollo/client';

export const GET_PATIENT_DETAILS = gql`
  query GetPatientDetails($patientId: MongoID!) {
    patient(_id: $patientId) {
      exams {
        technology
      }
      birthDate
      identificationType
      identificationNumber
      phone
      email
    }
  }
`;

export const UPDATE_PATIENT_DETAILS = gql`
  mutation UpdatePatientDetails(
    $updatePatientId: MongoID!
    $updatePatientRecord: UpdateByIdPatientInput!
  ) {
    updatePatient(_id: $updatePatientId, record: $updatePatientRecord) {
      record {
        birthDate
        identificationType
        identificationNumber
        phone
        email
      }
    }
  }
`;
