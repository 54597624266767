import { Box, Button, ButtonProps, Typography, styled } from '@mui/material';

export const DropZoneContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const ErrorHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  height: '16px',
  textTransform: 'none',
  textAlign: 'center',
}));

interface IDropZoneProps extends ButtonProps {
  hasError?: boolean;
}

export const DropZone = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<IDropZoneProps>(({ theme, hasError }) => ({
  background:
    'transparent linear-gradient(180deg, #010104 0%, #0A0A0D 38%, #161619 100%, #2B2C2E 100%) 0% 0% no-repeat padding-box',
  boxShadow: `0px 0px 6px ${
    hasError ? theme.palette.error.main : theme.palette.primary.main
  }`,
  borderRadius: 5,
  opacity: 1,
  width: '450px',
  height: '200px',
  'input[type="file"]': {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
}));

export const LabelWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  textTransform: 'none',
  textAlign: 'center',
  '& > img': {
    width: ' 20px',
    height: '20px',
    marginRight: '10px',
  },
}));

export const DropZoneList = styled('ol')(() => ({
  listStyle: 'none',
}));

export const DropZoneItem = styled('li')(() => ({
  maxWidth: '420px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
