import { Tooltip } from '@mui/material';
import { useStatus } from './hooks/useStatus';

import StatusButton from './StatusButton';
import StatusMenu from './StatusMenu';

const Status = () => {
  const { anchorEl, isStatusOpen, handleStatusButtonClick } = useStatus();

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        enterDelay={300}
        enterNextDelay={0}
        title="Account and help"
      >
        <StatusButton onClick={handleStatusButtonClick} />
      </Tooltip>
      <StatusMenu
        anchorEl={anchorEl}
        isOpen={isStatusOpen}
        onClose={handleStatusButtonClick}
      />
    </>
  );
};

export default Status;
