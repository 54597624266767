export const PAGE_TITLES = {
  dashboard: 'Dashboard',
  'my-account': 'My Account',
  'create-ai-exam': 'Create AI Exam',
  'create-patient': 'Create Patient',
  'loading-predictions': 'Validate AI Analysis',
  validation: 'Validate AI Analysis',
  'study-manager': 'Study Manager',
  'patients-view': 'Patients View',
  about: 'About',
};
