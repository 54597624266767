import { useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useRemoveExam } from 'components/pages/CreateExam/ExamPanel/examPanel.hooks';
import { IFilterTabValues } from 'components/pages/CreateExam/ExamPanel/examPanel.types';
import { OutlinedButton, SubmitButton } from 'components/system/buttons';
import ContainerLoading from 'components/system/ContainerLoading/ContainerLoading';
import ModalFooter from 'components/system/Modal/ModalFooter';
import { ExamStatus } from 'constants/exams';
import { Types } from 'mongoose';
import { useFeedbackModal } from 'providers';
import { useState } from 'react';
import { ExamObject } from 'types/mongoose-types';
import { logError } from 'utils/logError';
import { useUpdateExam } from '../examForms/examForms.hooks';
import { GET_EXAM_FOR_RESUBMIT } from './resubmitExamModalContent.api';
import { handleFilterSubmit } from './resubmitExamModalContent.helpers';
import {
  IconWrapper,
  StyledFooter,
  StyledMessage,
  StyledTitle,
} from './resubmitExamModalContent.styles';
import { IResubmitExamModalContent } from './resubmitExamModalContent.types';

const ResubmitExamModalContent = ({
  examId,
  onClose,
  onCompleted,
}: IResubmitExamModalContent) => {
  const { data, loading } = useQuery<{ exam: ExamObject }>(
    GET_EXAM_FOR_RESUBMIT,
    {
      variables: { examId },
      skip: !examId,
    },
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { removeExam } = useRemoveExam();
  const { openFeedbackModal } = useFeedbackModal();
  const { updateExam } = useUpdateExam(new Types.ObjectId(examId));

  if (!data?.exam && loading) {
    return <ContainerLoading />;
  }

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!data?.exam) {
        return;
      }
      await handleFilterSubmit(data.exam.filters as IFilterTabValues, data.exam)
        .then(async () => {
          // update database exam with selected filters
          await updateExam({
            variables: {
              updateExamId: data.exam?._id,
              updateExamRecord: {
                filters: data.exam.filters as IFilterTabValues,
                status: ExamStatus.UnderAnalysis,
              },
            },
          });
        })
        .then(() => {
          onCompleted?.();
          onClose();
        });
    } catch (error) {
      openFeedbackModal({
        type: 'error',
        title: 'Submission Error',
        message: 'Error re-submitting exam, please try again',
      });
      logError({ error });
    }

    setIsSubmitting(false);
  };

  const onDelete = () => {
    openFeedbackModal({
      type: 'warning',
      title: 'Delete exam',
      message: 'Are you sure you want to delete this exam?',
      cancelBtn: {
        label: 'No',
      },
      submitBtn: {
        label: 'Delete',
        onClick: async () => {
          await removeExam({ variables: { removeExamId: data?.exam._id } })
            .then(() => {
              onClose();
            })
            .catch((error: unknown) => logError({ error }));
        },
      },
    });
  };

  return (
    <>
      <IconWrapper>
        <InfoOutlinedIcon />
      </IconWrapper>

      <StyledTitle variant="h3" align="center">
        Re-submit exam
      </StyledTitle>
      <StyledMessage variant="body1" align="center">
        {`The previous exam submission failed.\nDo you wish to retry submitting the exam?`}
      </StyledMessage>
      <StyledFooter>
        <ModalFooter handleCancel={onClose}>
          <OutlinedButton
            variant="contained"
            onClick={onDelete}
            disabled={isSubmitting}
          >
            DELETE EXAM
          </OutlinedButton>
          <SubmitButton
            autoFocus
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            RE-SUBMIT
          </SubmitButton>
        </ModalFooter>
      </StyledFooter>
    </>
  );
};

export default ResubmitExamModalContent;
