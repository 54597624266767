import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/pt-br';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const ADAPTER_LOCALES = {
  'en-US': 'en',
  'en-CA': 'en-ca',
  'en-GB': 'en-gb',
  es: 'es',
  'fr-FR': 'fr',
  pt: 'pt',
  'pt-PT': 'pt',
  'pt-BR': 'pt-br',
} as const;

export const NAVIGATOR_LOCALE =
  ADAPTER_LOCALES[navigator.language as keyof typeof ADAPTER_LOCALES];

dayjs.locale(NAVIGATOR_LOCALE);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

// Timezones
dayjs.extend(utc);
dayjs.extend(timezone);

export const LOCALE_FORMAT = dayjs.localeData().longDateFormat('L');
