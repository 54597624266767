import { PrivateAssetTypeEnum, getPrivateAssetUrl } from './privateAssets';

export interface IGetPrivateUserAvatarUrlParams {
  firebaseId: string;
  institutionId: string;
  assetPath?: string;
}

export const getPrivateUserAvatarUrl = ({
  firebaseId,
  institutionId,
  assetPath = '',
}: IGetPrivateUserAvatarUrlParams) => {
  return getPrivateAssetUrl({
    type: PrivateAssetTypeEnum.INTERFACE,
    firebaseId,
    institutionId,
    assetPath,
  });
};
