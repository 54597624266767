export enum FindingPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum ExamStatus {
  Draft = 'draft',
  UploadError = 'upload_error',
  UnderAnalysis = 'under_analysis',
  AnalysisError = 'analysis_error',
  ReadyToValidate = 'ready_to_validate',
  Validated = 'validated',
  Deleted = 'deleted',
}
