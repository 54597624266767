import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import ContainerLoading from 'components/system/ContainerLoading/ContainerLoading';
import { useSession } from 'providers';
import AppBar from '../AppBar/AppBar';
import AppDrawer from '../AppDrawer/AppDrawer';
import { getPageTitle } from './pageBase.helper';
import { AppWrapper, InnerPage, PageWrapper } from './PageBase.styles';

export const drawerWidth = 240;

const PageBase = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { loading, user } = useSession();
  const location = useLocation();

  const activePage = location.pathname.split('/')[2];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (loading || !user) {
    return <ContainerLoading />;
  }

  return (
    <AppWrapper>
      <AppBar title={getPageTitle(activePage)} open={drawerOpen} />
      <AppDrawer
        open={drawerOpen}
        activePage={activePage}
        handleDrawerToggle={handleDrawerToggle}
      />
      <PageWrapper open={drawerOpen}>
        <InnerPage>
          <Outlet />
        </InnerPage>
      </PageWrapper>
    </AppWrapper>
  );
};

export default PageBase;
