import { forwardRef } from 'react';

import { StyledTextField } from './components';
import { IFormTextField } from './formTextField.types';

const FormTextField = forwardRef<HTMLInputElement, IFormTextField>(
  ({ error, ...props }: IFormTextField, ref) => (
    <StyledTextField
      type="text"
      error={!!error}
      helperText={<>{error ?? null}</>}
      autoComplete="off"
      InputLabelProps={{
        ...(!!props.value || !!props.defaultValue || !!props.disabled
          ? { shrink: true }
          : {}),
        ...props.InputLabelProps,
      }}
      {...props}
      ref={ref}
    />
  ),
);

export default FormTextField;
